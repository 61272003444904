import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// pacakges
import OtpInput from "react-otp-input";
import styled, { keyframes } from "styled-components";

// Store
import { Context } from "../../../contexts/Store";

// axios
import { PatientConfig } from "../../../axiosConfig";

export default function DeleteOtpVerification({ onClose, phone }) {
    const { dispatch, state } = useContext(Context);
    const [otp, setOtp] = useState();
    const [error, setError] = useState("");
    const [isTimerRunning, setIsTimerRunning] = useState(true);
    const [resendOTPTime, setResendOTPTime] = useState(15);
    const [isShowOtp, setIsShowOtp] = useState(true);
    const navigate = useNavigate();

    let accessToken = state.user_details.access_token;
    let email = state.user_details.email;
    let mobileNumber = state.user_details.phone_number;

    const handleDeleteSubmit = () => {
        setError("");
        if (!otp) {
            setError("Enter a  valid otp");
            // console.log("entered in if")
        } else {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("otp", otp);
            PatientConfig.delete(
                "api/v1/patients/patient/profile-delete/email-otp-verification/",
                {
                    data: formData,
                    headers: {
                        //   "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        window.localStorage.clear();
                        window.location.reload();
                        // navigate("/auth/login");
                    }
                    // console.log('Resource updated:', response.data)
                    // Handle success as needed
                    // console.log('success', otp)
                    // console.log('success', email)
                })
                .catch((error) => {
                    setError(error.response.data.data.message);

                    // console.log(typeof otp, 'error', otp)
                    // console.log('error', email)
                });
        }
    };

    const handleResendOTP = () => {
        const resendOtpData = new FormData();
        resendOtpData.append("country", "IN");
        resendOtpData.append("phone", mobileNumber);

        PatientConfig.post(
            "api/v1/patients/authentication/resend-otp/",
            resendOtpData,
            {}
        ).then((response) => {
            const { StatusCode, data } = response.data;
            if (StatusCode === 6000) {
                if (response?.data?.data?.remaining_attempts === 0) {
                    setIsShowOtp(false);
                }
            }
            if (StatusCode === 6001) {
                setError((prevError) => ({
                    ...prevError,
                    otp: data.message,
                }));
            }
        });

        setIsTimerRunning(true);
        setResendOTPTime(15);
    };
    useEffect(() => {
        let intervalId;
        if (isTimerRunning && resendOTPTime > 0) {
            intervalId = setInterval(() => {
                setResendOTPTime((prevTime) => prevTime - 1);
            }, 1000);
        } else if (resendOTPTime === 0) {
            setIsTimerRunning(false);
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [isTimerRunning, resendOTPTime]);
    return (
        <Modal onClick={() => onClose()}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <Card>
                    <Title>Delete Your Account</Title>
                    <Description>
                        Are you sure you want to delete your account. If you
                        want to delete your account, enter the OTP sent to
                        <span> {email}</span>
                    </Description>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        inputType="tel"
                        renderInput={(props) => <input {...props} />}
                        containerStyle="container"
                        inputStyle="otp-input"
                    />
                    <span style={{ color: "red", fontSize: "11px" }}>
                        {error}
                    </span>
                    <SwitchLine>
                        {!isShowOtp ? (
                            <GreyText>You have crossed limits </GreyText>
                        ) : (
                            <GreyText>Haven’t received an OTP </GreyText>
                        )}
                        {isShowOtp && (
                            <>
                                {!isTimerRunning && (
                                    <BlueText
                                        onClick={() => {
                                            handleResendOTP();
                                        }}
                                    >
                                        Resend
                                    </BlueText>
                                )}
                                {isTimerRunning && (
                                    <BlueText onClick={() => {}}>
                                        Resend in {resendOTPTime}s
                                    </BlueText>
                                )}
                            </>
                        )}
                    </SwitchLine>

                    <NavButtons>
                        <Cancel onClick={() => onClose()}>Cancel</Cancel>
                        <Delete
                            onClick={() => {
                                handleDeleteSubmit();
                            }}
                        >
                            Delete
                        </Delete>
                    </NavButtons>
                </Card>
            </ModalContent>
        </Modal>
    );
}
const popup = keyframes`
    0%{
        scale: 0.5;
    }
    100%{
        scale: 1;
    }
`;
const Modal = styled.section`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${THEME_COLORS.modal_bg};
    position: fixed;
    left: 0;
    top: 0;
    z-index: 303;
`;
const ModalContent = styled.div`
    width: 450px;
    border-radius: 24px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    background: ${THEME_COLORS.chips_green_on_container};
    padding-top: 5px;
    animation: ${popup} 0.4s ease-in-out;

    @media all and (max-width: 480px) {
        width: 100%;
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        animation-name: modal;
        animation-duration: 0.3s;

        @keyframes modal {
            from {
                transform: translateY(100%);
            }
            to {
                transform: translateY(0%);
            }
        }
    }
`;
const Card = styled.div`
    border-radius: 24px;
    background: ${THEME_COLORS.white};
    padding: 32px;
    @media all and (max-width: 480px) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
const Title = styled.h4`
    font-family: "DM_sans_medium";
    font-size: 20px;
    margin-bottom: 20px;
    color: ${THEME_COLORS.text_title};
`;
const Description = styled.p`
    font-family: "DM_sans";
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
    margin-bottom: 20px;
    span {
        color: ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
const NavButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`;
const Cancel = styled.div`
    border-radius: 32px;
    padding: 8px 20px;
    border: 2px solid ${THEME_COLORS.light_400};
    color: ${THEME_COLORS.dark_500};
    font-size: 14px;
    width: 48%;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;
const Delete = styled.div`
    border-radius: 32px;
    padding: 10px 20px;
    color: ${THEME_COLORS.white};
    background: ${THEME_COLORS.secondary_gradient};
    font-size: 14px;
    width: 48%;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;
const SwitchLine = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
`;
const GreyText = styled.span`
    color: ${THEME_COLORS.text_para};
    /* font-weight: 600; */
    /* font-size: 14px; */
    font-family: "DM_sans_medium";
    font-size: 0.875rem;
`;
const BlueText = styled.span`
    color: ${THEME_COLORS.chips_blue_on_container};
    font-weight: 600;
    /* font-size: 14px; */
    font-size: 0.875rem;
    margin-left: 4px;
    cursor: pointer;
`;
